import React from "react";
import { API, graphqlOperation, Auth } from "aws-amplify";
import MaterialTable from "material-table";
import { Button } from "@material-ui/core";
import { listShows } from "graphql/queries";
import * as Router from "@reach/router";

let nextToken: string;

async function listUsers(limit: number) {
  let apiName = "AdminQueries";
  let path = "/listUsers";
  let myInit = {
    queryStringParameters: {
      limit: limit,
      token: nextToken,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  const { NextToken, ...rest } = await API.get(apiName, path, myInit);
  nextToken = NextToken;

  console.log(rest);
  return rest;
}

export interface ShowProps extends Router.RouteComponentProps {}
function Shows(props: ShowProps) {
  // const history = useHistory();
  const location = Router.useLocation();

  const saveShow = (show: any) => {
    // @todo スマートなのないか
  };

  return (
    <>
      <div>
        <Button
          component={Router.Link}
          to="/cms/admin/shows/new"
          state={{ background: location }}
        >
          新規
        </Button>
      </div>

      <MaterialTable
        title="ユーザ一覧"
        columns={[
          { title: "Username", field: "Username", editable: "never" },
          { title: "UserStatus", field: "UserStatus" },
        ]}
        onRowClick={(evt, selectedRow) => saveShow(selectedRow)}
        data={(query) =>
          new Promise((resolve, reject) => {
            async function f() {
              const res = await listUsers(10);
              console.log("users:", res.Users);
              // }
              const { Users } = res;
              resolve({
                data: Users || [],
                page: 0,
                totalCount: Users.length,
              });
            }
            f();
          })
        }
      />
    </>
  );
}

export default Shows;
