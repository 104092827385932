import React from "react";
import * as Router from "@reach/router";
import Table from "components/molecures/Table";
export interface ShowProps extends Router.RouteComponentProps {}

function Shows(props: ShowProps) {
  return (
    <>
      <Table type="shows" dialog hidePreview />
    </>
  );
}

export default Shows;
