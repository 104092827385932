import * as React from "react";
import * as Router from "@reach/router";

import Form from "components/organisims/Form";
import { boothBoatType } from "settings/FormSettings";

export default function Page() {
  const { action, ...rest } = Router.useParams();
  return <Form dataType="boothBoatType" formParams={boothBoatType} />;
}
