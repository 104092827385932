import * as React from "react";
import Table from "components/molecures/Table";
import * as Router from "@reach/router";
// export interface ShowProps extends Router.RouteComponentProps {}

type ShowProps = {
  showID?: String;
} & Router.RouteComponentProps;

export default function Pages(props: ShowProps) {
  const { showID } = props;
  const variables = {};
  return (
    <>
      <Table type="pages" dialog variables={variables} hidePreview />
    </>
  );
}
