import * as React from "react";
import * as Router from "@reach/router";
import PreviewMain from "components/pages/cms/shows/preview/Main";

import Form from "components/organisims/Form";
import { topPage } from "settings/FormSettings";

export default function Page() {
  console.log("TopPage");
  const { action, ...rest } = Router.useParams();

  return <Form dataType="topPage" formParams={topPage} />;
}
