import * as React from "react";
import Table from "components/molecures/Table";
import * as Router from "@reach/router";
// export interface ShowProps extends Router.RouteComponentProps {}

type Props = {} & Router.RouteComponentProps;

export default function TopPages(props: Props) {
  console.log("TopPages");
  const variables = {};
  return (
    <>
      <Table
        type="topPages"
        variables={variables}
        createLimit={1}
        hidePreview
      />
    </>
  );
}
